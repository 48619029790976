<template>
  <div class="flex h-screen overflow-hidden">
    <SidebarAdmin :username="userStore.username || 'Guest User'" />
    <main class="flex-grow overflow-y-auto">
      <NuxtPage />
    </main>
  </div>
</template>

<script setup lang="ts">
const { status } = useAuth()
const userStore = useUserStore()
const router = useRouter()

// Fetch user data when layout is mounted
onMounted(async () => {
  if (status.value === 'authenticated') {
    try {
      const { data } = useAuth()

      if (data.value?.user) {
        userStore.setUser({
          fullName: data.value.user.fullName,
          email: data.value.user.email,
          username: data.value.user.username
        })
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }
})

// Watch auth status changes
watch(status, async (newStatus) => {
  if (newStatus === 'unauthenticated') {
    userStore.clearUser()
    router.push('/sign-in')
  }
})
</script>
