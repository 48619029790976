<template>
  <nav class="fixed bottom-0 left-0 right-0 bg-white border-t flex justify-around items-center h-16 z-50">
    <NuxtLink v-for="item in navItems" :key="item.name" :to="item.link" class="flex flex-col items-center py-1 w-full">
      <Icon :name="item.icon" size="1.5em" :class="{ 'text-blue-500': $route.path === item.link }" />
      <span class="text-xs mt-1">{{ item.name }}</span>
    </NuxtLink>
  </nav>
</template>

<script setup>
const navItems = [
  { name: 'Home', icon: 'lucide:home', link: '/' },
  { name: 'Link in Bio', icon: 'lucide:link', link: '/link-in-bio' },
  { name: 'Store', icon: 'lucide:shopping-bag', link: '/store' },
  { name: 'Email', icon: 'lucide:mail', link: '/email-marketing' },
  { name: 'More', icon: 'lucide:menu', link: '/more' },
];
</script>

<style scoped>
nav {
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
</style>