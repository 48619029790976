<template>
  <div>
    <!-- Desktop Sidebar -->
    <aside v-if="!isMobile" class="h-screen bg-white border-r flex flex-col transition-all duration-300 sticky top-0"
      :class="[isCollapsed ? 'w-16' : 'w-64']">
      <!-- Logo Container -->
      <div class="p-4 flex items-center justify-between">
        <div class="flex items-center">
          <img src="https://placehold.co/400" alt="Beacons" class="w-8 h-8">
          <span v-if="!isCollapsed" class="ml-2 text-xl font-bold">yally</span>
        </div>
        <button v-if="!isCollapsed" @click="toggleSidebar" class="bg-white border rounded-full p-1">
          <Icon name="lucide:chevron-left" size="1.2em" />
        </button>
      </div>

      <!-- Toggle Button (when collapsed) -->
      <button v-if="isCollapsed" @click="toggleSidebar" class="mx-auto mb-4 bg-white border rounded-full p-1">
        <Icon name="lucide:chevron-right" size="1.2em" />
      </button>

      <!-- Navigation -->
      <nav class="flex-grow px-2">
        <NuxtLink v-for="(item, index) in navItems" :key="index" :to="item.link"
          class="sidebar-link flex items-center px-2 py-2 mb-1 rounded-lg transition-colors duration-200"
          :class="{ 'bg-gray-100': $route.path === item.link }">
          <Icon :name="item.icon" :class="{ 'text-blue-500': $route.path === item.link }"
            :size="isCollapsed ? '1.5em' : '1.2em'" />
          <span v-if="!isCollapsed" class="ml-3" :class="{ 'font-medium': $route.path === item.link }">{{ item.name
            }}</span>
          <span v-if="!isCollapsed && item.badge" class="ml-auto text-xs bg-green-500 text-white px-2 py-1 rounded">{{
            item.badge }}</span>
        </NuxtLink>

        <!-- Pinned Apps -->
        <div :class="[isCollapsed ? '' : 'mt-6 px-2']">
          <h3 v-if="!isCollapsed" class="text-xs font-semibold text-gray-500 mb-2 px-2">PINNED APPS</h3>
          <NuxtLink v-for="app in pinnedApps" :key="app.name" :to="app.link"
            class="sidebar-link flex items-center px-2 py-2 mb-1 rounded-lg transition-colors duration-200"
            :class="{ 'bg-gray-100': $route.path === app.link }">
            <Icon :name="app.icon" :class="{ 'text-blue-500': $route.path === app.link }"
              :size="isCollapsed ? '1.5em' : '1.2em'" />
            <span v-if="!isCollapsed" class="ml-3" :class="{ 'font-medium': $route.path === app.link }">{{ app.name
              }}</span>
          </NuxtLink>
        </div>
      </nav>

      <!-- Upgrade Section -->
      <div v-if="!isCollapsed" class="p-4 bg-orange-50 border-t">
        <div class="flex items-center mb-2">
          <Icon name="lucide:crown" class="text-pink-500 mr-2" />
          <span class="font-semibold">Upgrade & Go Pro!</span>
          <button class="ml-auto text-gray-400">&times;</button>
        </div>
        <p class="text-sm text-gray-600 mb-3">Get 0% selling fees, custom domains, payment plans, unlimited email sends,
          AI tools and much more!</p>
        <button class="w-full bg-pink-500 text-white py-2 rounded font-semibold">LEARN MORE</button>
      </div>

      <!-- User Profile -->
      <div class="p-4 border-t flex items-center">
        <img src="https://placehold.co/400" alt="User" class="w-8 h-8 rounded-full">
        <span v-if="!isCollapsed" class="ml-3 text-sm font-medium">{{ username }}</span>
        <Icon v-if="!isCollapsed" name="lucide:chevron-right" class="ml-auto" />
      </div>
    </aside>

    <!-- Mobile Bottom Navigation -->
    <BottomNavBar v-else />
  </div>
</template>

<script setup>
defineProps({
  username: {
    type: String,
    required: true,
    default: 'Guest User'
  }
});

const isCollapsed = ref(false);
const isMobile = ref(false);

const toggleSidebar = () => {
  isCollapsed.value = !isCollapsed.value;
};

const checkMobile = () => {
  isMobile.value = window.innerWidth < 768; // Adjust breakpoint as needed
};

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', checkMobile);
});

const navItems = [
  { name: 'Home', icon: 'lucide:home', link: '/home' },
  { name: 'Communities', icon: 'lucide:users', link: '/communities' },
];

const pinnedApps = [
  { name: 'Link in Bio', icon: 'lucide:link', link: '/link-in-bio' },
  { name: 'Website', icon: 'lucide:globe', link: '/website' },
  { name: 'Merchandise', icon: 'lucide:shopping-bag', link: '/store' },
  { name: 'Email Marketing', icon: 'lucide:mail', link: '/email-marketing' },
  { name: 'Setting', icon: 'lucide:cog', link: '/media-kit' },
];

const handleSignOut = async () => {
  const { signOut } = useAuth();
  const userStore = useUserStore();

  try {
    await signOut();
    userStore.clearUser();
    // Router navigation will be handled by the watch in admin layout
  } catch (error) {
    console.error('Error signing out:', error);
  }
};
</script>

<style scoped>
.sidebar-link {
  transition: all 0.3s ease;
}

.sidebar-link:hover {
  background-color: #f3f4f6;
}
</style>
